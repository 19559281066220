<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import mapRouter from "@/components/maps/leaflet/mapsRouters";
import {leerRutas, leerRutaPuntos} from "@/api/routers";
import { leerSiguiente } from "@/api/points";
import myLoader from "@/components/myLoader.vue";
import {leerDivisionTerritorial} from "@/api/system";
import {leerVehiculo,leerConductorActivo, leerCargueDescargue } from "@/api/operaciones/vehicles";
import {leerVehiculoNS, activarTelefono, asignarRuta } from "@/api/trucks";
import {actualizarTelefonoConductor } from "@/api/drivers";
import store from "@/state/store";
import Swal from "sweetalert2";



export default {
  computed: {
    plate()  {
      // Puedes acceder al valor de 'plate' desde el store
      // store.getters("plate/getPlate");
      return  store.getters["plate/getPlate"];
    },
  },
  created() {
   
  },  
  page: {
    title: "Gestión de Viajes",
    meta: [{ name: "Gestión de Viajes", content: appConfig.description }],
  },
  data() {
    return {
      title: "Gestión de Viajes",
      items: [
        {
          text: "Gestión de Viajes",
          href: "/Mod_NS_Travel/ns_FollowUpTravel/",
        },
        {
          text: "Gestión de Viajes",
          active: true,
        },
      ],
      swD:[1,0,0,1,1],
      isLoader:false,
      dataRouters:[],
      dataPoints:[],
      coordinateNew:{lat:0,lng:0},
      divisionTerritorial:[],
      search:"",
      codeRouteId:'',
      codePointId:'',
      PointsNum:null,
      rutaActual:{codigo: "",nombre: "",},
      LatLng:{lat:0,lng:0},
      infoVehiculo:{},
      infoVehiculoNS:{},
      infoConductorActivo:{},
      infoLoadUnload:{},
      gps_empresa_URL:  null, 
    };
  },
  components: {
    Layout,
    PageHeader,
    mapRouter,
    myLoader,
  },
  methods: {

    showLoadAndUnload(){
      if(this.swD[2]==0){this.readLoadAndUnload(this.plate);}
      this.toogleSw(2);
    },
    async readLoadAndUnload(plate){
      this.isLoader=true;
      const info = await leerCargueDescargue({"plate":plate, });
      console.log("info-79",info.result.data.data)
      if (info.result.data.code==200){
        this.infoLoadUnload=info.result.data.data
        
      }    
      this.isLoader=false;
    },
    
    toogleSw(i){
      this.swD[i]=(this.swD[i]==1)?0:1
    },

    updatePhone(DRIVERID,phoneIdentifier) {
      Swal.fire({
        title: "Actualizar número telefónico "+phoneIdentifier,
      
        icon: "warning",
        input:"text",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
      }).then ((result) => {
        if (result.value) {
          this.actualizarTelefono1(DRIVERID,phoneIdentifier,result.value);
         
        }
      });
    },

    async actualizarTelefono1(DRIVERID,phoneIdentifier, phone){
      this.isLoader=true;
      await actualizarTelefonoConductor(
              {"DRIVERID":DRIVERID, 
              "phoneIdentifier":phoneIdentifier,
              "phone":phone
      });
      await this.get_ActiveDriver(this.plate);
      
      await Swal.fire("Actualizado!"+DRIVERID,phone, "success");
      
    },
    
    activatePhone:async function(phone) {
      this.isLoader=true;
      const result = await activarTelefono({"plate":this.plate, "activePhone":phone});
      await this.get_vehicleNS(this.plate);
      this.isLoader=false;
      if(result.data.code!=200) {alert("error query");}

    },  

    filterRouters: async function() {
      if(this.search.length >= 3 || this.search.length==0) {
        await this.get_dataRuoter(this.search);
      }
    }, 
    setCodeRouteId:function(data){
      this.codeRouteId=data
    }, 
    setPointId:function(data){
      this.coordinateNew={lat:0,lng:0}
      this.PointsNum=data
    },  
    setPoint: function(e,edit=false,coordinateNew={lat:0,lng:0}){
      this.LatLng=e;
      this.coordinateNew=coordinateNew;
      if(edit==true){
        this.PointsNum='';
        this.PointsNum='PX';
        var infoPoint2 = this.dataPoints.filter((infoPoint) =>{
           return infoPoint.Latitude == this.LatLng.lat && 
                  infoPoint.Longitude == this.LatLng.lng    ;
        });
        this.PointsNum=infoPoint2[0].PointsNum;
        document.getElementById("btnEditPoints").click();
      }else{
        document.getElementById("btnCreatePoints").click();
      }  

    },
    setRutaActual:function(data){
      this.rutaActual = {codigo: data.CodeRoute,nombre: data.Description,};
      this.get_dataPoints(data.CodeRoute);
      this.setRouter(this.plate, data.CodeRoute);
    },

    async setRouter(plate, route){
      this.isLoader=true
      await asignarRuta({"plate":plate, "route":route});
      this.isLoader=false;
    },

    updateNewRoute:function(data){
      this.search=data.Description;
      this.get_dataRuoter( this.search);
      this.setRutaActual(data);
    },
    async get_nextPointNumber() {
      this.isLoader=true
      const info = await leerSiguiente();
      if (info.result.data.code==200){
        this.PointsNum=info.result.data.data
      }    
      this.isLoader=false;
    },  
    async get_divisionTerritorial() {
      this.isLoader=true
      const result = await leerDivisionTerritorial();
      this.divisionTerritorial = [];
      result.result.data.data.map((item) => {
        this.divisionTerritorial.push({
          name: item.NOMBREDIVISION , 
          value: item.CODIGODIVISION,
        });
      });
      this.isLoader=false;
    },

    async get_vehicle(VEHICLEID) {
      const result = await leerVehiculo({"VEHICLEID":VEHICLEID});
      this.infoVehiculo = result.result.data.data;
      this.gps_empresa_URL=this.infoVehiculo.gps_empresa.URL;
      this.get_ActiveDriver(VEHICLEID)
      this.get_vehicleNS(VEHICLEID)
    },

    async get_ActiveDriver(VEHICLEID) {
      const result = await leerConductorActivo({"VEHICLEID":VEHICLEID});
      this.infoConductorActivo= result.result.data.data;
    },

    async get_vehicleNS(plate) {
      const result = await leerVehiculoNS({"plate":plate});
      this.infoVehiculoNS = result.data.data;
      if ( this.infoVehiculoNS.route==null){
        Swal.fire("RUTA DE VIAJE", "Defina una Ruta de Viaje", "danger");
        this.swD[1]=1;
      }
    },


    async get_dataPoints(CodeRoute){
      this.isLoader=true
      const result = await leerRutaPuntos({"CodeRoute":CodeRoute})
      this.dataPoints = [];
      result.data.data[0].puntos.map((item) => {
        this.dataPoints.push({
          "PointsNum": item.PointsNum,
          "City": item.City,
          "Description": item.Description,
          "Latitud": item.Latitud,
          "Longitud":item.Longitud,
          "Type": item.Type,
          "Latitude": item.Latitude,
          "Longitude": item.Longitude,
        })
      })    
      this.PointsNum='PX' //inicializa punto de referencia
      this.isLoader=false 
    },

    async get_dataRuoter(filter) {
      this.isLoader=true
   
      const result = await leerRutas({
        page:1,
        pageSize:10,
        filter: filter
      });
      this.dataRouters = [];
      this.isLoader=false;
        result?.result?.data?.data?.map((item) => {
          this.dataRouters.push({
            CodeRoute: item.CodeRoute,
            Description: item.Description,
            Status:item.Status
          });
                  
          if(this.rutaActual.codigo=="" && this.infoVehiculoNS.route==item.CodeRoute){
            this.rutaActual.codigo=item.CodeRoute;
            this.rutaActual.nombre=item.Description;
            this.get_dataPoints(item.CodeRoute);
          }

        });

       
   
    },  
  },  
 

  async mounted() {
    this.isLoader=true;
    await this.get_divisionTerritorial();
    await this.get_vehicle(this.plate);
    await this.get_dataRuoter("");
    this.isLoader=false;
  }  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="w-50">
        <div class="card mb-1">
          <div class="card-header">
            <div class="d-lg-flex justify-content-between">
              <h3><span>Vehículo:</span>
                <span class="text-danger">{{plate}}</span>
              </h3>
              <a :href="'https://'+infoVehiculo?.gps_empresa?.URL" target="_blank" class="btn-label waves-effect waves-light">
                <b-button variant="info" class="btn-label waves-effect waves-light">
                    <i class="ri-error-warning-line label-icon align-middle fs-16 me-2"></i>GPS Site
                </b-button>
              </a>
            </div>
          </div>
        </div>   
        <div v-if="swD[0]==1"
          class="card mb-1"> 
          <div class="card-header">
            <div class="d-lg-flex justify-content-between mt-2">
              <div>
                <span class="ps-0" >Usuario_GPS:</span>
                <span class="text-muted">{{infoVehiculo?.GPSUser}}</span>
              </div>
              <div>
                <span class="ps-0" scope="row">Clave_GPS:</span>
                <span class="text-muted">{{infoVehiculo?.GPSPassword}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-1"> 
          <div class="card-body">
            <table class="table table-borderless">
                <tr class="" style='border-bottom: inset 1pt'>
                  <th class="ps-0">Conductor</th>
                  <th class="ps-0">Celular 1</th>
                  <th class="ps-0">Celular 2</th>
                </tr>
              
                <tr class="" style='border: inset 0pt'
                  v-for="item in infoConductorActivo" :key="item.Driver"
                >
                  <td class="text-muted" >{{item.Name1+' '+item.Name2+' '+item.LastName1+' '+item.LastName2}}</td>
                    <td class="text-muted" >{{item.Phone1}}
                      <i v-if="infoVehiculoNS.activePhone==item.Phone1"
                      class="ri-checkbox-fill text-secondary"
                      data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Teléfono Activo"
                      ></i>
                      <i v-if="infoVehiculoNS.activePhone!=item.Phone1"
                      @click="activatePhone(item.Phone1)"
                      class="ri-checkbox-blank-line text-secondary"
                      data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Activar este Teléfono"
                      ></i>
                      <i @click="updatePhone(item.Driver,'1')"
                      class="ri-pencil-fill text-success"
                      data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Actualizar Teléfono"
                      ></i>
                  </td>
                  <td class="text-muted">{{ item.Phone2 }}
                    <i v-if="infoVehiculoNS.activePhone==item.Phone2"
                    class="ri-checkbox-fill text-secondary"
                    data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Teléfono Activo"
                    ></i>
                    <i v-if="infoVehiculoNS.activePhone!=item.Phone2"
                    @click="activatePhone(item.Phone2)"
                    class="ri-checkbox-blank-line text-secondary"
                    data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Activar este Teléfono"
                    ></i>
                    <i @click="updatePhone(item.Driver,'2')"
                    class="ri-pencil-fill text-success"
                    data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Actualizar Teléfono"
                    ></i>
                  </td>
                </tr>
            </table>
          </div>
        </div>    
        <div class="card mt-1">
          <div class="card-body">
            <div class="d-flex flex-wrap gap-2">
            
              <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Usuario GPS">
                <a  @click="toogleSw(0)"
                  href="javascript:void(0);"
                  class="avatar-xs d-block"
                >
                  <span
                    class="avatar-title rounded-circle fs-16  bg-primary text-light"
                  >
                    <i class="ri-error-warning-line"></i>
                  </span>
                </a>
              </div>
              <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Definir Ruta">
                <a  @click="toogleSw(1)" 
                  href="javascript:void(0);"
                  class="avatar-xs d-block"
                >
                  <span
                    class="avatar-title rounded-circle fs-16 bg-danger"
                  >
                    <i class="ri-global-fill"></i>
                  </span>
                </a>
              </div>
              <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Cargues y Descargues">
                <a @click="showLoadAndUnload" 
                  href="javascript:void(0);"
                  class="avatar-xs d-block"
                >
                  <span
                    class="avatar-title rounded-circle fs-16 bg-success"
                  >
                    <i class="ri-dribbble-fill"></i>
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  class="avatar-xs d-block"
                >
                  <span
                    class="avatar-title rounded-circle fs-16 bg-dark"
                  >
                    <i class="ri-pinterest-fill"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <div v-if="swD[1]==1">
          <div class="search-box">
            <input type="text" class="form-control bg-light border-light" 
            v-on:keyup="filterRouters" v-model="search"
            placeholder="Buscar ruta (al menos 3 leras)..." />
            <i class="ri-search-2-line search-icon"></i>
          </div>
          <div class="chat-room-list" data-simplebar>
            <div class="chat-message-list">
              <SimpleBar class="list-unstyled chat-list chat-user-list " >
                <table class="table table-info table-striped align-middle table-nowrap mb-0">
                  <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Ruta</th>
                      </tr>
                  </thead>
                  <tbody> 
                      <tr v-for="ruta in dataRouters" :key="ruta.CodeRoute">
                          <th scope="row" > 
                            <a href="javascript:void(0);"  @Click="setRutaActual(ruta)" 
                            :class="ruta.Status=='A'?'link-danger':'link-disable'">{{ruta.CodeRoute}}</a>
                          </th>
                          <td>{{ ruta.Description }}</td>
                      </tr>
                  </tbody>
                </table>
              </SimpleBar>
            </div>

          
        
          </div>
        </div>
        <div v-if="swD[2]==1">
          <div class="card mt-1">
            <div class="card-body">
              <div data-simplebar style="height: 235px;" class="mx-n3 px-3">
                  <div class="vstack gap-3">
                      <div class="d-flex align-items-center">
                          <div class="avatar-xs flex-shrink-0 me-3">
                              <img src="@/assets/images/users/avatar-2.jpg" alt="" class="img-fluid rounded-circle">
                          </div>
                          <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="#" class="text-body d-block">Nancy Martino</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                  <button type="button" class="btn btn-light btn-sm">Message</button>
                                  <div class="dropdown">
                                      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-more-fill"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end member item -->
                      <div class="d-flex align-items-center">
                          <div class="avatar-xs flex-shrink-0 me-3">
                              <div class="avatar-title bg-soft-danger text-danger rounded-circle">
                                  HB
                              </div>
                          </div>
                          <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="#" class="text-body d-block">Henry Baird</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                  <button type="button" class="btn btn-light btn-sm">Message</button>
                                  <div class="dropdown">
                                      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-more-fill"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end member item -->
                      <div class="d-flex align-items-center">
                          <div class="avatar-xs flex-shrink-0 me-3">
                              <img src="@/assets/images/users/avatar-3.jpg" alt="" class="img-fluid rounded-circle">
                          </div>
                          <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="#" class="text-body d-block">Frank Hook</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                  <button type="button" class="btn btn-light btn-sm">Message</button>
                                  <div class="dropdown">
                                      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-more-fill"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end member item -->
                      <div class="d-flex align-items-center">
                          <div class="avatar-xs flex-shrink-0 me-3">
                              <img src="@/assets/images/users/avatar-4.jpg" alt="" class="img-fluid rounded-circle">
                          </div>
                          <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="#" class="text-body d-block">Jennifer Carter</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                  <button type="button" class="btn btn-light btn-sm">Message</button>
                                  <div class="dropdown">
                                      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-more-fill"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end member item -->
                      <div class="d-flex align-items-center">
                          <div class="avatar-xs flex-shrink-0 me-3">
                              <div class="avatar-title bg-soft-success text-success rounded-circle">
                                  AC
                              </div>
                          </div>
                          <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="#" class="text-body d-block">Alexis Clarke</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                  <button type="button" class="btn btn-light btn-sm">Message</button>
                                  <div class="dropdown">
                                      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-more-fill"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end member item -->
                      <div class="d-flex align-items-center">
                          <div class="avatar-xs flex-shrink-0 me-3">
                              <img src="@/assets/images/users/avatar-7.jpg" alt="" class="img-fluid rounded-circle">
                          </div>
                          <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="#" class="text-body d-block">Joseph Parker</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                  <button type="button" class="btn btn-light btn-sm">Message</button>
                                  <div class="dropdown">
                                      <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-more-fill"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</a></li>
                                          <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end member item -->
                  </div>
                  <!-- end list -->
              </div>
                
                <!-- <ul >
                  <li class="" v-for="sites in infoLoadUnload" :key="sites.delivery">
                    dsdsad
                  </li>
                </ul> -->
              
            </div>
          </div>  
        </div>
        <!-- end card -->
      </div>
      <!-- end chat leftsidebar -->
      <!-- Start User chat -->
      <div class="card w-80 overflow-hidden py-0 px-0">
        <div class="card-body">
         <mapRouter class="leaflet-map" 
          @setPoint="setPoint"
          :dataPoints="dataPoints"
          :rutaActual="rutaActual"
         /> 
        </div>
      </div>
    </div>
    <!-- end chat-wrapper -->
    <!-- Myloader -->
    <div v-if="isLoader" style="position:absolute; top: 35%; 
        left:20%;
        z-index: 9999;  "  >
          <myLoader /> 
    </div>

    </Layout>
    <!-- end Myloader -->
    <!-- Crear Ruta-->
    <!-- end Crear Ruta -->
</template>