<template>
  <div id="searchPlace" class="form-control-sm form-floating d-flex justify-content-between align-items-center w-100">
    <div class="d-flex">
      <input type="text" name="addr" value="" id="addr"  class="form-control-sm w-15 me-2"/>
       
        <b-button pill variant="primary" size="sm" @click="addr_search" class="btn-label waves-effect waves-light me-2">
            <i class="ri-search-line label-icon align-middle rounded-pill fs-16 me-2"></i> Buscar
        </b-button>
     
        <b-button pill variant="secondary" size="sm" @click="zoomSet(1)" class="btn-label waves-effect waves-light me-2">
            <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i> País
        </b-button>

        <b-button pill variant="success" size="sm" @click="zoomSet(2)" class="btn-label waves-effect waves-light me-2">
            <i class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i> Dpto.
        </b-button>
        <b-button pill variant="warning" size="sm" @click="zoomSet(3)" class="btn-label waves-effect waves-light me-2">
            <i class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i> Últ. Lugar
        </b-button>
    </div>
    <span id="customText" class="text-dark text-right ">
      {{ rutaActual.codigo }} - {{ rutaActual.nombre }} 
    </span> 
  </div>
  
  <div id="resultsPlace" >
    <ul>
      <li v-for="item in items" :key="item.id">
        <a href="javascript:void(0)" @click="chooseAddr(item.lat,item.lon)">
        {{ item.display_name }}  
      </a>
      </li>
    </ul>
  </div>

  <div id="mapContainer"></div>

</template>
<style>
#customText {
  font-size: 14px; /* Ajusta el tamaño de fuente según tus necesidades */
  background-color: red; /* Color de fondo rojo */
  color: white; /* Texto en blanco */
  padding: 5px 8px;
  border-radius: 4px;
}
</style>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: "LeafletMap",
  data() {
    return {
      map: null,
      marker:null,
      circle:null,
      popup:null,
      myFind:null,
      searchON:false,
      items:[],
      defaultIcon:null,
      oldPoint:null,
      transportIcon:[],
      lastPoint:[4.099917, -72.908813],
    };
  },
  components() {},
  props:{
    rutaActual: { type: Object, required: false,
      default(){
          return {
              codigo:'XXX',
              nombre: 'Nombre',
          }
      }
    },
    dataPoints:{ type: Array, required: false}
  },
  methods: {

    onOldMarkerClick: function onClick(e) {
      this.oldPoint=e.target.getLatLng();
    }, 
   
    onMarkerClick: function onClick(e) {
      var coordedana=e.type == "dragend"?e.target._latlng:e.latlng
      this.lastPoint = coordedana;
      this.$emit("setPoint",coordedana,false);
    },  
    onMarkerEditClick: function onClick(e) {
      console.log('target-1',e);
      console.log('target-2',e.oldLatLng);
      console.log('target-3',e.target.dragging._marker.dragging._marker._latlng);

      var coordedana=e.latlng;
      var coordedanaNew={lat:0,lng:0};

      if(e.type == "dragend"){
        coordedana=this.oldPoint
        coordedanaNew=e.target.dragging._marker.dragging._marker._latlng
      }
      console.log("coordedanaNew-map-105",coordedanaNew)
      this.lastPoint = coordedana;
      this.$emit("setPoint",coordedana,true, coordedanaNew);
    }, 

    zoomSet: function(zone){
      switch (zone) {
        case 1:
          this.map.setView([4.099917, -72.908813], 6);
          break;
        case 2:
          this.map.setView(this.lastPoint, 10);
          break;
        case 3:
          this.map.setView(this.lastPoint, 17);
          break;
      }  
    },
    
    onMapClick(e) {
        // this.popup
        //     .setLatLng(e.latlng)
        //     .setContent("You clicked the map at " + e.latlng.toString())
        //     .openOn(this.map);
        L.marker(e.latlng ,{ icon: this.defaultIcon, draggable:'true'})
        .addTo(this.map)
        .on('click', this.onMarkerClick)
        .on('dragend', this.onMarkerClick);
    },

    chooseAddr(lat,lon){
      this.map.setView([lat,lon], 17);
      L.marker([lat,lon] ,{ icon: this.defaultIcon, draggable:'true' }).addTo(this.map)
      .on('click', this.onMarkerClick)
      .on('dragend', this.onMarkerClick);
      
    },

    async addr_search() {
  
      var inp = document.getElementById("addr");
     
      const data=await 
      fetch('https://nominatim.openstreetmap.org/search?format=json&limit=5&q=' + inp.value)
        .then(response => response.json())
      
      this.items = [];
      data.map((val) => {
        this.items.push(
        { display_name:val.display_name ,
          lat:val.lat,
          lon:val.lon,}
        );
      });  
    }
  },  
 
  watch: {
    async dataPoints(){
      // if(data=='' || data==null ) return;
      //borra punto que no están por coordenadas
      const miMap = this.map;
      await this.map.eachLayer(function (layer) {
          if (layer instanceof L.Marker) {
            miMap.removeLayer(layer);
          }
      });

      await this.dataPoints.map((item) => {
        L.marker([item.Latitud, item.Longitud] ,{ icon: this.transportIcon[item.Type], draggable:'true'  })
        .addTo(this.map)  
        .on('dragend',this.onMarkerEditClick)
        .on('dragstart', this.onOldMarkerClick)
        .on('click', this.onMarkerEditClick)
      });
    
    },
  },
 
  mounted() {
    this.map = L.map("mapContainer").setView([11.0099785,-74.8277434], 17);
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    //use a mix of renderers
    //this.marker = L.marker([11.010075, -74.825491]).addTo(this.map);
    this.circle = L.circle([11.010075, -74.825491], {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 10
    }).addTo(this.map);
    //this.marker.bindPopup("<b>Hello world!</b><br>I am a popup.").openPopup();
    // this.circle.bindPopup("I am a circle.");

    // this.popup = L.popup();
    // this.map.setView([11.010075, -74.825491], 17);
    this.map.on('dblclick', this.onMapClick);
    // this.map.on('dragend', this.onMapClick);
    
    
    this.defaultIcon = L.icon({
      iconUrl: require('../../../assets/images/leaflet/marker-icon.png'),
      iconSize:     [25, 65], // size of the icon
      iconAnchor:   [15, 70], // point of the icon which will correspond to marker's location
      popupAnchor:  [-3, -70] // point from which the popup should open relative to the iconAnchor
    });

     // inicializa icons
    this.transportIcon["G"] = L.icon({ 
      iconUrl: require('../../../assets/images/traficIcons/gastation.png'),
      iconSize:   [25, 65], // size of the icon
      iconAnchor: [15, 70], // point of the icon which will correspond to marker's location
      popupAnchor:[-3, -70] // point from which the popup should open relative to the iconAnchor
    });
    this.transportIcon["H"] = L.icon({
      iconUrl: require('../../../assets/images/traficIcons/hotel.png'),
      iconSize:   [25, 65], // size of the icon
      iconAnchor: [15, 70], // point of the icon which will correspond to marker's location
      popupAnchor:[-3, -70] // point from which the popup should open relative to the iconAnchor
    });
    this.transportIcon["P"] = L.icon({
      iconUrl: require('../../../assets/images/traficIcons/parking.png'),
      iconSize:   [25, 65], // size of the icon
      iconAnchor: [15, 70], // point of the icon which will correspond to marker's location
      popupAnchor:[-3, -70] // point from which the popup should open relative to the iconAnchor
    });
    this.transportIcon["R"] = L.icon({
      iconUrl: require('../../../assets/images/traficIcons/repair.png'),
      iconSize:   [25, 65], // size of the icon
      iconAnchor: [15, 70], // point of the icon which will correspond to marker's location
      popupAnchor:[-3, -70] // point from which the popup should open relative to the iconAnchor
    });
    this.transportIcon["F"] = L.icon({
      iconUrl: require('../../../assets/images/traficIcons/restaurant.png'),
      iconSize:   [25, 65], // size of the icon
      iconAnchor: [15, 70], // point of the icon which will correspond to marker's location
      popupAnchor:[-3, -70] // point from which the popup should open relative to the iconAnchor
    });
   

  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },

  
};
</script>

<style scoped>
#mapContainer {
  width: 80vw;
  height: 70vh;
}
div#search {
  background-color: white;
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: auto;
  height: auto;
  padding: 10px;
}
div#searchPlace input {
  width: 200px;
}
div#resultsPlace {
  font-style: sans-serif;
  color: black;
  font-size: 100%;
}
</style>